<template>
	<div class="container">
		<div v-if="show2" class="show_zs" @click="show2=false">
			<img :src="src" alt="">
			<div class="container_box_ti_btn">
				<van-button class="btn_style btn_32" type="primary" color="#49C7DE"
					style="letter-spacing: 0;">
					长&nbsp;&nbsp;&nbsp;按&nbsp;&nbsp;&nbsp;保&nbsp;&nbsp;&nbsp;存
				</van-button>
			</div>
		</div>
		<div class="container_title"><img src="../../assets/img/awards_top.png" style="width: 85%;" alt=""></div>
		<div class="container_box">
			<infoForm @formClick="formClick" :btn-flag="btnFlag" for-type="1" @btnInfo="btnInfo"></infoForm>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;display: block;padding: 25px 0px;margin: auto;" alt="">
	</div>
</template>

<script>
	import infoForm from '@/components/infoForm'
	import {
		areaList
	} from '@vant/area-data'
	import {
		getInfo,
		getRegister,
		profileData,
		uploadData,
	} from '@/api'
	import {
		Toast,
		Dialog
	} from 'vant';
import router from '../../router'
	export default {
		name: "info",
		data() {
			return {
				areaList,
				value: '',
				status: false,
				show: false,
				show2: false,
				src: '',
				cascaderValue: '',
				btnFlag: false,
			}
		},
		components: {
			infoForm
		},
		created() {
			if (localStorage.getItem('token') == null) {
				this.goLogin()
			}
		},
		methods: {
			goLogin(){
				Dialog.confirm({
						title: '提示',
						message: '未登录，请前往登录',
						showCancelButton: false,
					})
					.then(() => {
						this.$router.push({
							name: 'Index',
						})
					})
			},
			onOpen() {
				this.show = true
			},
			onClose() {
				this.status = false
				this.show = false
			},
			handleClick() {
				this.$router.go(-1)
			},
			savePhoto(){
				var alink = document.createElement("a");
				alink.href = this.src;
				alink.download = "这是一张图片"; //图片名
				alink.click();
			},
			formClick(e, type) {
				if (type == 'comfiirmInfo') {
					Dialog.confirm({
						title: '提示',
						message: '生成证书后个人信息无法修改，请核对好个人信息！',
					})
					.then(() => {
						this.handleUpload()
					})
				} else {
					profileData(e).then(res => {
						if (res.data.code == 0) {
							this.$toast.fail(res.data.msg)
						} else {
							this.$toast.success(res.data.msg)
						}
					})
				}
			},
			handleUpload() {
				if (localStorage.getItem('token') == null) {
					this.$toast.fail('请登录后操作!')
					return
				}
				uploadData().then(res => {
					console.log(res)
					if (res.data.code == 0) {
						this.$toast.fail(res.data.msg)
					} else {
						this.show2 = true
						this.src = 'http://api.aizhutidati.kc87.com' + res.data.data
					}
				})
			},
			btnInfo(e) {
				if (e == 0) {
					this.btnFlag = false
				}
				if (e == 1) {
					this.btnFlag = true
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	html {
		background-color: #50D8D6;
	}

	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		background: url(../../assets/img/allbanner.png) no-repeat center 0;
		background-size: 100% 100%;
		.show_zs{
			position: fixed;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 9999;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img{
				width: 80%;
			}
		}
		.left_icon {
			box-sizing: border-box;
			padding-top: 20px;
		}

		&_title {
			box-sizing: border-box;
			width: 100%;
			padding-top: 90px;
			text-align: center;
			font-size: 55px;
			color: #fff;
			letter-spacing: 0.3em;
		}

		&_box {
			padding: 80px 30px 40px;
			width: 650px;
			box-sizing: border-box;
			border-radius: 25px;
			background-color: #fff;
			margin: auto;

			&_item {
				display: flex;
				align-items: center;
				margin-bottom: 38px;

				.item_label {
					font-size: 25px;
					font-weight: bold;
				}

				img {
					width: 52px;
					height: 52px;
				}

				.item {
					display: flex;
					align-items: center;

					.inp {
						width: 455px;
						height: 43px;
						border: 1PX solid #969495;
						border-radius: 20px;
						padding-left: 10px;
						color: #969495;
						font-size: 26px;
					}

					.item_border {
						font-size: 28px;
						border: 1PX solid #969495;
						color: #969495;
						border-radius: 10px;
						padding: 10px 85px 10px 25px;
						margin-right: 24px;
						box-sizing: border-box;
					}

				}
			}
		}
	}
	.container_box_ti_btn {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.btn_style {
		width: 450px;
		height: 42PX;
		line-height: 80px;
		border: 5px solid #fff !important;
		color: #fff;
		font-weight: bolder;
		font-family: "微软雅黑";
		letter-spacing: 6px;
		margin-top: 70px;
	}
</style>